import {useEffect} from '@wordpress/element';
import {useAppDispatch} from '../store';
import styled from 'styled-components';
import {createTheme, ThemeProvider} from '@mui/material/styles';
import {grey} from '@mui/material/colors';
import SCSS from './App.module.scss';
import SVG from './SVG';
import siteplan from '../images/siteplan-20211014.jpg';
import GothamMedium from '../fonts/Gotham-Medium.otf';
import GothamBold from '../fonts/Gotham-Bold.ttf';
import {fetchUnits} from '../store/availabilitySlice';

const theme = createTheme({
	palette: {
		primary: {
			main: grey[50],
		},
	},
});

const AppRoot = styled.div`
	@font-face {
		font-family: 'Gotham';
		font-weight: 500;
		src: url(${GothamMedium}) format('opentype');
	}
	@font-face {
		font-family: 'Gotham';
		font-weight: bold;
		src: url(${GothamBold}) format('opentype');
	}
`;

function App() {
	const dispatch = useAppDispatch();
	useEffect(() => {
		dispatch(fetchUnits());
	});
	return (
		<ThemeProvider theme={theme}>
			<AppRoot>
				<div className={SCSS.App}>
					<div className={SCSS.Sitemap}>
						<img src={siteplan} alt='siteplan' />
						<div className={SCSS.SVGContainer}>
							<SVG />
						</div>
					</div>
				</div>
			</AppRoot>
		</ThemeProvider>
	);
}

export default App;
