import SCSS from './SVG.module.scss';
import Units from './Units';
import Outline from './Outline';
import Overlay from './Overlay';
import Buildings from './Buildings';
import LabelWhite from './LabelWhite';
import LabelBlack from './LabelBlack';
import Logo from './Logo';
import Legend from './Legend';

const SVG = () => {
	return (
		<svg viewBox='0 0 1920 1249' className={SCSS.SVG}>
			<Units />
			<Outline />
			<Overlay />
			<Buildings />
			<LabelWhite />
			<LabelBlack />
			<Logo />
			<Legend />
		</svg>
	);
};

export default SVG;
