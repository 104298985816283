import SCSS from './SVG.module.scss';

const Legend = () => {
	return (
		<g id='LEGEND' className={SCSS.Legend}>
			<g>
				<rect
					className={SCSS.Box__Available}
					x='1297.27'
					y='1175.5'
					width='25.85'
					height='25.85'
				/>
				<text
					className={SCSS.Legend__Text}
					transform='matrix(1 0 0 1 1329.136 1192.5691)'
				>
					AVAILABLE
				</text>
			</g>
			<g>
				<rect
					className={SCSS.Box__Hold}
					x='1457.08'
					y='1175.5'
					width='25.85'
					height='25.85'
				/>
				<text
					className={SCSS.Legend__Text}
					transform='matrix(1 0 0 1 1490.3307 1192.5691)'
				>
					HOLD
				</text>
			</g>
			<g>
				<rect
					className={SCSS.Box__Sold}
					x='1596.82'
					y='1175.5'
					width='25.85'
					height='25.85'
				/>
				<text
					className={SCSS.Legend__Text}
					transform='matrix(1 0 0 1 1633.0173 1192.5691)'
				>
					SOLD
				</text>
			</g>
			<g>
				<rect
					className={SCSS.Box__Unavailable}
					x='1736.82'
					y='1175.5'
					width='25.85'
					height='25.85'
				/>
				<text
					className={SCSS.Legend__Text}
					transform='matrix(1 0 0 1 1773.0173 1192.5691)'
				>
					UNAVAILABLE
				</text>
			</g>
		</g>
	);
};

export default Legend;
