import SCSS from './SVG.module.scss';

const Overlay = () => {
	return (
		<g id='OVERLAY' className={SCSS.Overlay}>
			<polygon
				points='1018.67,465.43 1031.02,428.12 1005.55,410.75 1144.55,209.26 1161.72,219.95 1179.64,193.38
		1490.42,106.34 1480.85,94.04 1560.6,55.3 1625.76,0 1923.5,1.53 1923.5,314.14 1673.16,461.03 1657.21,415.3 1644.9,392.65
		1653.56,390.7 1564.7,229.38 1536.9,239.86 1483.13,270.78 1453.97,235.76 1388.8,285.89 1316.8,390.24 1309.31,385.55
		1252.55,468.21 1196.5,429.27 1168.25,426.24 1144.09,480.47 1127.92,505.08 	'
			/>
			<path
				d='M1314.82,536.37l-36.15,38.58l-22.18,68.66l-69.57,122.16l-59.24,133.33c0,0-10.56,47.99-33.84,85.21
		c-23.27,37.22-40.29,52.11-40.29,52.11l-20.66,20.04l-114.84,153.43l-681.42-32.51L190.47,1249h888.61l17.32-31.52l124.86-57.02
		l147.34-432.71l65.92-41.61l13.67-64.68l-13.37-55.43l-12.46-7.48L1314.82,536.37z'
			/>
			{/*	<path*/}
			{/*		d='M309.88,1180.87l20.94-86l348.09-535.11L718,506.9l40.36-49.22l80.57,63.8l52.11-55.59l62.49,58.06*/}
			{/*l-84.58,91.92l-77.93,117.52l100.02,65.16l-2.84,3.73l73.08,47.52c0,0-9.94,21.51-19.21,35.94c-9.27,14.43-25.67,38.28-25.67,38.28*/}
			{/*l89.32,61.22l-164.28,221L309.88,1180.87z'*/}
			{/*	/>*/}
		</g>
	);
};

export default Overlay;
