import SCSS from './SVG.module.scss';

const Outline = () => {
	return (
		<g id='OUTLINES' className={SCSS.Outline}>
			<g>
				<polygon
					points='820.07,482.05 821.21,483.24 830.53,474.29 831.5,475.31 846.14,461.25 845.17,460.23 854.48,451.29 
			853.34,450.1 862.26,441.53 863.4,442.72 872.72,433.78 873.69,434.79 888.33,420.73 887.36,419.72 896.68,410.77 895.53,409.59 
			900.17,405.13 868.22,371.86 783.31,453.4 815.26,486.67 		'
				/>
				<polygon
					points='736.88,585.8 738.17,586.82 746.15,576.66 747.25,577.53 759.79,561.57 758.68,560.7 766.66,550.54 
			765.36,549.52 773,539.79 774.3,540.81 782.27,530.65 783.38,531.52 795.91,515.56 794.81,514.69 802.78,504.53 801.49,503.52 
			805.46,498.46 769.18,469.97 696.48,562.56 732.76,591.05 		'
				/>
				<polygon
					points='663.74,696.89 665.13,697.79 672.16,686.96 673.34,687.72 684.39,670.7 683.21,669.93 690.24,659.1 
			688.86,658.2 695.59,647.83 696.98,648.73 704.01,637.89 705.19,638.66 716.24,621.64 715.06,620.87 722.09,610.04 720.71,609.14 
			724.21,603.75 685.52,578.63 621.42,677.37 660.11,702.49 		'
				/>
				<polygon
					points='591.29,808.31 592.67,809.21 599.7,798.38 600.88,799.14 611.93,782.12 610.75,781.35 617.79,770.52 
			616.4,769.62 623.14,759.25 624.52,760.14 631.55,749.31 632.73,750.08 643.78,733.05 642.6,732.29 649.64,721.46 648.25,720.56 
			651.76,715.17 613.07,690.05 548.97,788.79 587.66,813.91 		'
				/>
				<polygon
					points='519.29,920.18 520.67,921.08 527.7,910.25 528.88,911.01 539.93,893.99 538.75,893.22 545.79,882.39 
			544.4,881.5 551.14,871.12 552.52,872.02 559.55,861.19 560.73,861.95 571.78,844.93 570.6,844.16 577.64,833.33 576.25,832.43 
			579.76,827.04 541.07,801.93 476.97,900.66 515.66,925.78 		'
				/>
				<polygon
					points='466.41,1004.64 473.44,993.81 472.06,992.91 478.8,982.54 480.18,983.44 487.21,972.6 488.39,973.37 
			499.44,956.35 498.26,955.58 505.29,944.75 503.91,943.85 507.41,938.46 468.73,913.34 404.63,1012.08 443.31,1037.2 
			446.95,1031.6 448.33,1032.5 455.36,1021.67 456.54,1022.43 467.59,1005.41 		'
				/>
				<polygon
					points='355.29,1103.62 348.07,1098.93 395.93,1025.22 434.62,1050.33 431.12,1055.72 432.5,1056.62 
			425.46,1067.45 426.64,1068.22 415.59,1085.24 414.41,1084.48 407.38,1095.31 406,1094.41 399.26,1104.79 400.65,1105.68 
			393.61,1116.52 394.79,1117.28 389.32,1125.71 396.54,1130.4 390.96,1138.99 389.78,1138.23 382.75,1149.06 381.37,1148.16 
			377.73,1153.76 339.05,1128.64 		'
				/>
				<polygon
					points='572.31,1050.02 533.51,1025.08 529.66,1031.07 519.48,1024.53 495.3,1062.16 505.47,1068.7 
			498.12,1080.13 488.19,1073.75 464.01,1111.37 473.94,1117.76 469.86,1124.11 508.66,1149.05 518.26,1134.11 514.9,1131.95 
			527.84,1111.81 531.21,1113.97 549.7,1085.2 546.34,1083.03 559.28,1062.9 562.64,1065.06 		'
				/>
				<polygon
					points='630.07,953.07 633.43,955.23 643.09,940.2 604.29,915.26 600.44,921.25 590.27,914.71 566.08,952.34 
			576.26,958.87 568.91,970.31 558.98,963.92 534.79,1001.55 544.72,1007.93 540.64,1014.29 579.44,1039.23 589.05,1024.29 
			585.68,1022.12 598.62,1001.98 601.99,1004.15 620.49,975.37 617.12,973.21 		'
				/>
				<polygon
					points='701,844.16 704.37,846.32 714.03,831.28 675.23,806.35 671.38,812.34 661.21,805.8 637.02,843.42 
			647.19,849.96 639.85,861.39 629.92,855.01 605.73,892.64 615.66,899.02 611.58,905.38 650.38,930.31 659.98,915.38 
			656.62,913.21 669.56,893.07 672.93,895.24 691.42,866.46 688.06,864.3 		'
				/>
				<polygon
					points='710.33,752.18 700.4,745.8 676.21,783.42 686.14,789.81 682.06,796.16 720.86,821.1 730.46,806.16 
			727.1,804 740.04,783.86 743.41,786.02 761.9,757.25 758.54,755.09 771.48,734.95 774.85,737.11 784.51,722.07 745.71,697.13 
			741.86,703.12 731.69,696.58 707.5,734.21 717.68,740.75 		'
				/>
				<polygon
					points='783.42,642.35 773.79,635.52 747.89,671.99 757.52,678.82 753.15,684.98 790.76,711.68 801.04,697.2 
			797.78,694.89 811.64,675.37 814.9,677.68 834.7,649.79 831.44,647.48 845.3,627.96 848.56,630.27 858.91,615.7 821.3,588.99 
			817.18,594.8 807.32,587.8 781.42,624.27 791.28,631.27 787.34,636.82 		'
				/>
				<polygon
					points='868.95,519.13 877.81,527.36 868.57,537.32 859.91,529.29 829.49,562.08 838.15,570.11 
			833.01,575.65 866.83,607.02 878.91,594 875.97,591.28 892.25,573.73 895.19,576.45 918.45,551.37 915.52,548.65 931.8,531.1 
			934.73,533.82 946.89,520.71 913.07,489.34 908.23,494.56 899.37,486.34 		'
				/>
				<polygon
					points='561.39,1121.95 597.82,1145.37 610.34,1125.88 598.7,1118.4 601.39,1114.21 613.03,1121.7 
			624.49,1103.88 615.06,1097.82 620.85,1088.81 630.27,1094.87 641.56,1077.31 629.92,1069.83 632.53,1065.76 644.18,1073.24 
			656.67,1053.81 620.24,1030.4 		'
				/>
				<polygon
					points='667.16,1012.76 678.81,1020.24 690.26,1002.42 680.83,996.36 686.62,987.35 696.05,993.41 
			707.33,975.86 695.69,968.37 698.31,964.3 709.95,971.79 722.44,952.35 686.01,928.94 627.17,1020.5 663.59,1043.91 
			676.12,1024.42 664.48,1016.94 		'
				/>
				<polygon
					points='774.96,870.39 787.45,850.96 751.03,827.55 692.18,919.1 728.61,942.52 741.13,923.03 729.49,915.55 
			732.18,911.36 743.82,918.85 755.27,901.03 745.84,894.97 751.63,885.96 761.06,892.02 772.35,874.46 760.7,866.98 763.32,862.91 
					'
				/>
				<polygon
					points='891.04,625.62 892.49,626.95 873.55,647.69 905.53,676.89 921.15,659.79 910.93,650.46 
			914.25,646.82 923.01,654.82 937.3,639.18 929.02,631.62 932.63,627.67 936.24,623.72 944.51,631.28 958.59,615.86 948.37,606.53 
			951.63,602.96 961.85,612.29 977.43,595.23 945.46,566.03 		'
				/>
				<polygon
					points='800.41,805.81 812.38,813.5 823.9,795.58 811.93,787.88 814.44,783.99 826.4,791.68 838.76,772.46 
			802.1,748.9 757.73,817.94 794.38,841.5 807.15,821.63 795.18,813.94 797.59,810.2 		'
				/>
				<polygon
					points='879.52,713.9 892.56,695.14 856.78,670.27 809.94,737.66 845.72,762.53 859.2,743.14 847.52,735.02 
			853.04,727.08 864.72,735.2 876.88,717.7 865.19,709.58 867.84,705.78 		'
				/>
				<polygon
					points='803.34,1030.21 815.92,1010.64 819.32,1012.82 838.68,982.69 835.29,980.51 847.87,960.94 
			851.26,963.12 861.19,947.67 822.39,922.73 818.3,929.1 808.21,922.61 783.96,960.34 794.05,966.83 786.35,978.81 776.26,972.32 
			752.01,1010.05 762.1,1016.53 758.5,1022.14 797.3,1047.08 806.74,1032.4 		'
				/>
				<polygon
					points='918.35,851.82 921.74,854 931.68,838.55 892.87,813.61 888.78,819.99 878.69,813.5 854.44,851.23 
			864.53,857.71 860.93,863.32 856.83,869.69 846.74,863.2 822.49,900.93 832.58,907.42 828.98,913.02 867.78,937.96 877.22,923.28 
			873.82,921.1 886.4,901.52 889.8,903.71 909.16,873.58 905.77,871.39 		'
				/>
				<polygon
					points='988.73,741.86 992.12,744.04 1002.05,728.59 963.25,703.65 959.15,710.02 949.06,703.54 
			924.81,741.27 934.9,747.75 931.3,753.36 927.21,759.73 917.12,753.24 892.87,790.97 902.96,797.46 899.36,803.06 938.16,828 
			947.59,813.32 944.2,811.14 956.78,791.56 960.17,793.75 979.54,763.62 976.15,761.43 		'
				/>
				<polygon
					points='753.88,1104.2 759.07,1107.53 768.51,1092.85 765.11,1090.67 777.69,1071.1 781.09,1073.28 
			791.02,1057.83 752.22,1032.89 748.12,1039.26 738.03,1032.78 713.78,1070.5 723.87,1076.99 720.27,1082.59 720.26,1082.6 
			717.97,1081.12 713.87,1087.49 700.89,1079.15 677.33,1115.81 687.42,1122.3 686.73,1123.36 683.13,1128.97 721.93,1153.91 
			731.37,1139.23 727.97,1137.04 740.55,1117.47 743.95,1119.65 753.88,1104.2 		'
				/>
				<polygon
					points='971.46,682.27 979.6,688.35 975.25,694.16 1012.26,721.83 1023.11,707.32 1020.57,705.42 
			1027.52,696.12 1028.77,697.05 1036.31,686.97 1035.06,686.03 1045.43,672.16 1010.96,646.4 1006.34,652.57 998.21,646.49 		'
				/>
				<polygon
					points='861.88,1091.86 857.39,1097.8 848.6,1091.16 835.13,1108.99 843.92,1115.63 839.59,1121.36 
			830.8,1114.72 803.76,1150.51 812.55,1157.15 808.06,1163.09 841.59,1188.43 852.6,1173.85 853.69,1174.67 868.31,1155.31 
			867.22,1154.49 877.45,1140.96 878.54,1141.78 878.54,1141.78 885.6,1132.44 884.51,1131.62 895.41,1117.19 		'
				/>
				<polygon
					points='960.6,960.2 956.1,966.24 946.46,959.06 919.77,994.91 929.41,1002.09 925.54,1007.3 921.37,1012.9 
			911.72,1005.72 884.98,1041.64 894.62,1048.82 890.48,1054.39 923.77,1079.18 934.25,1065.11 935.34,1065.92 949.6,1046.75 
			948.52,1045.94 968.93,1018.51 970.02,1019.32 984.26,1000.19 983.17,999.38 993.89,984.99 		'
				/>
				<polygon
					points='1006.34,904.66 1002.23,910.97 992.16,904.41 967.76,941.85 977.84,948.41 974.29,953.86 
			1009.07,976.52 1018.3,962.34 1019.44,963.08 1032.46,943.1 1031.32,942.36 1041.12,927.33 		'
				/>
				<polygon
					points='1054.49,782.47 1050.77,789.01 1040.32,783.06 1018.21,821.91 1028.66,827.85 1025.45,833.5 
			1030.06,836.12 1026.34,842.65 1015.89,836.7 1004.96,855.91 1009.4,858.43 998.23,878.05 1008.68,884 1005.47,889.65 
			1041.55,910.17 1049.92,895.47 1051.1,896.14 1057.11,885.56 1052.68,883.04 1058.45,872.91 1057.27,872.24 1066.14,856.64 
			1061.54,854.02 1069.9,839.32 1071.08,839.99 1082.87,819.27 1081.69,818.59 1090.57,802.99 		'
				/>
				<polygon
					points='1138.28,714.13 1102.5,693.08 1098.69,699.57 1088.32,693.47 1077.12,712.52 1088.65,719.31 
			1084.83,725.79 1074.47,719.7 1051.81,758.22 1062.17,764.31 1058.88,769.91 1094.65,790.96 1103.23,776.38 1104.4,777.07 
			1116.49,756.51 1115.32,755.83 1130.35,730.29 1129.18,729.6 		'
				/>
			</g>
		</g>
	);
};

export default Outline;
