import SCSS from './SVG.module.scss';

const Buildings = () => {
	return (
		<g id='BUILDINGS' className={SCSS.Buildings}>
			<polygon
				className={SCSS.Building__1}
				points='1151.23,525.13 1183.89,563.56 1199.38,550.49 1206.97,559 1234.62,535.46 1246.47,500.67 
		1235.84,497.18 1242.82,477.89 1194.82,461.03 1187.23,482.44 1191.78,486.24 1173.25,513.58 1167.94,509.94 	'
			/>
			<polygon
				className={SCSS.Building__2}
				points='1105.7,471.52 1113.98,448.25 1117.59,449.53 1130.14,414.2 1123.69,411.91 1132.06,388.35 
		1138.52,390.65 1145.89,369.89 1137.42,364.11 1151.52,343.45 1156.7,346.98 1170.25,327.12 1165.08,323.59 1179.31,302.73 
		1184.49,306.27 1205.91,274.88 1203,272.89 1216.96,252.44 1219.87,254.43 1233.68,234.19 1201.39,212.15 1191.34,226.88 
		1165.52,209.26 1158.22,219.95 1161.3,222.06 1141.53,251.04 1138.44,248.93 1117.15,280.13 1122.33,283.66 1108.3,304.23 
		1103.11,300.69 1086.57,324.93 1086.03,324.74 1076.56,351.37 1083.19,353.73 1075.02,376.73 1068.38,374.37 1055.49,410.65 
		1059.11,411.94 1047.56,444.43 1043.94,443.15 1039.51,455.62 1070.07,466.48 1064.21,482.97 1101.05,496.06 1109.31,472.81 	'
			/>
			<polygon
				className={SCSS.Building__2}
				points='1395.52,266.19 1391.35,260.82 1410.79,245.71 1414.96,251.08 1453.44,221.16 1449.27,215.79 
		1469.36,200.18 1473.53,205.54 1494.74,189.05 1470.79,158.24 1460.81,165.99 1440.57,139.95 1430.03,148.14 1433.12,152.11 
		1405.65,173.47 1402.57,169.5 1372.79,192.65 1377.67,198.93 1358.2,214.07 1353.31,207.79 1325.78,229.19 1316.09,243.31 
		1322.54,247.73 1308.41,268.31 1301.97,263.89 1269.78,310.79 1273.56,313.38 1254.38,341.33 1250.6,338.74 1242.52,350.52 
		1269.06,368.73 1261.96,379.07 1294.8,401.61 1309.98,379.48 1303.89,375.3 1317.79,355.04 1323.89,359.22 1351.87,318.45 
		1345.78,314.26 1359.62,294.1 1365.71,298.28 1378.06,280.28 1377.7,280.04 	'
			/>
			<polygon
				className={SCSS.Building__2}
				points='1530.01,281.97 1517.01,259.45 1464.52,289.76 1473.67,305.6 1492.23,294.88 1496.08,301.55 	'
			/>
		</g>
	);
};

export default Buildings;
