import SCSS from './SVG.module.scss';

const LabelWhite = () => {
	return (
		<g id='WHITE_LABELS' className={SCSS.LabelWhite}>
			<text
				className={SCSS.Small}
				transform='matrix(0.5609 -0.8279 0.8279 0.5609 1142.675 298.5591)'
			>
				<tspan x='0' y='0'>
					FUTURE
				</tspan>
				<tspan x='-16.63' y='13.2'>
					APARTMENTS
				</tspan>
			</text>
			<text
				className={SCSS.Small}
				transform='matrix(0.5609 -0.8279 0.8279 0.5609 1296.3259 328.6987)'
			>
				<tspan x='0' y='0'>
					FUTURE
				</tspan>
				<tspan x='-16.63' y='13.2'>
					APARTMENTS
				</tspan>
			</text>
			<text
				className={SCSS.Small}
				transform='matrix(1 0 0 1 1661.0281 235.5746)'
			>
				EXISTING MARINA
			</text>
			<text className={SCSS.Big} transform='matrix(1 0 0 1 1505.3645 805.7968)'>
				OKANAGAN LAKE
			</text>
			<text transform='matrix(1 0 0 1 593.6699 1227.3973)'>
				EXISTING ORCHARD
			</text>
			{/*<text*/}
			{/*	className={SCSS.Small}*/}
			{/*	transform='matrix(1 0 0 1 586.7744 800.2124)'*/}
			{/*>*/}
			{/*	FUTURE PHASES*/}
			{/*</text>*/}
			{/*<text*/}
			{/*	className={SCSS.Small}*/}
			{/*	transform='matrix(1 0 0 1 793.3623 1056.9866)'*/}
			{/*>*/}
			{/*	FUTURE PHASES*/}
			{/*</text>*/}
			<text
				className={SCSS.Small}
				transform='matrix(1 0 0 1 1297.9429 676.8942)'
			>
				<tspan x='0' y='0'>
					EXISTING
				</tspan>
				<tspan x='-11.39' y='13.2'>
					RESIDENTIAL
				</tspan>
			</text>
			<text
				className={SCSS.Small}
				transform='matrix(1 0 0 1 1145.474 1025.2159)'
			>
				<tspan x='0' y='0'>
					EXISTING
				</tspan>
				<tspan x='-11.39' y='13.2'>
					RESIDENTIAL
				</tspan>
			</text>
			<text
				className={SCSS.Small}
				transform='matrix(0.8746 -0.4849 0.4849 0.8746 1486.512 289.1988)'
			>
				<tspan x='0' y='0'>
					BEACH
				</tspan>
				<tspan x='-7.81' y='13.2'>
					PAVILION
				</tspan>
			</text>
			<text
				className={SCSS.Small}
				transform='matrix(0.7623 -0.6473 0.6473 0.7623 1170.6908 609.6512)'
			>
				POOL
			</text>
			<text
				className={SCSS.Small}
				transform='matrix(1 0 0 1 1555.1145 367.9507)'
			>
				<tspan x='0' y='0'>
					PICKLE
				</tspan>
				<tspan x='5.43' y='13.2'>
					BALL
				</tspan>
			</text>
			<g>
				<text
					className={SCSS.Tiny}
					transform='matrix(0.9403 -0.3404 0.3404 0.9403 1330.1144 114.135)'
				>
					MULTI-USE PATH
				</text>
			</g>
			<g>
				<text
					className={SCSS.Tiny}
					transform='matrix(0.5467 -0.8373 0.8373 0.5467 530.7579 751.4545)'
				>
					MULTI-USE PATH
				</text>
			</g>
		</g>
	);
};

export default LabelWhite;
