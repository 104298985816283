import {render} from '@wordpress/element';
import App from './app/App';
import {store} from './store';
import {Provider} from 'react-redux';
import * as serviceWorker from './serviceWorker';
import './sass/app.global.scss';

var applicationContainer = document.getElementById('availability-sitemap-root');

applicationContainer &&
	render(
		<Provider store={store}>
			<App />
		</Provider>,
		applicationContainer
	);

serviceWorker.unregister();
