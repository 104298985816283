import axios from 'axios';
import {createSlice, createAsyncThunk} from '@reduxjs/toolkit';
import {RootState} from '.';

const webiteURL = 'https://shelterbayliving.ca//wp-json/shelterbay/v1/posts/';

const _ = require('lodash');

export interface IUnit {
	id: number;
	unit: string;
	status: string;
	floorplan: 'U1' | 'U2' | 'D2' | 'D3' | 'A' | 'B' | 'E' | 'E1';
}

interface IinitialState {
	units: Array<IUnit>;
}

const initialState: IinitialState = {
	units: [],
};

export const fetchUnits = createAsyncThunk(
	'availability/fetchUnits',
	async () => {
		const rr = _.random(1, 100000);
		const newURL = `${webiteURL}?rr=${rr}`;
		const response = await axios.get(newURL);
		return response.data;
	}
);

export const availabilitySlice = createSlice({
	name: 'availability',
	initialState,
	reducers: {},
	extraReducers: {
		[fetchUnits.fulfilled.toString()]: (state, action: any) => {
			const data = action.payload;
			state.units = data;
		},
	},
});

// export const {} = availabilitySlice.actions;
export const selectUnit = (state: RootState) => state.availability.units;
export default availabilitySlice.reducer;
