import SCSS from './SVG.module.scss';

const Logo = () => {
	return (
		<g id='LOGO' className={SCSS.Logo}>
			<g>
				<path
					className={SCSS.White}
					d='M375.1,392.65H78.6c-8.01,0-14.51-6.5-14.51-14.51V71.36c0-8.01,6.5-14.51,14.51-14.51h296.5
			c8.01,0,14.51,6.5,14.51,14.51v306.77C389.61,386.15,383.11,392.65,375.1,392.65z'
				/>
				<path
					className={SCSS.Blue}
					d='M160.5,200.89c0,0,26.95,14.26,54.52-0.33c27.57-14.58,50.95-27.1,83.42-17.8c0,0-24.36-2.2-42.39,10.22
			C238.03,205.4,199.22,227.36,160.5,200.89'
				/>
				<path
					className={SCSS.Blue}
					d='M201.32,216.77c0,0,20.99,1.4,46.01-13.27c25.02-14.67,41.8-22.92,72.3-12.73c0,0-23.68-3.51-50.32,13.56
			C242.66,221.4,220.01,221.89,201.32,216.77'
				/>
				<path
					className={SCSS.LightBlue}
					d='M132.71,196.88c0,0,12.74-3.91,33.27,2.11c20.54,6.02,35.27,5.29,48.83-1.48c0,0-13.28,3.44-27.91-1.96
			C172.28,190.16,149.71,184.28,132.71,196.88'
				/>
				<path
					className={SCSS.LightBlue}
					d='M148.36,186.92c0,0,16.49-1.1,25.05,1.13c8.56,2.23,32.26,13.65,54.72,2.72c0,0-12.92,1.12-29.84-4.12
			C181.37,181.4,168.16,178.2,148.36,186.92'
				/>
				<path
					className={SCSS.LightBlue}
					d='M162.17,180.13c0,0,10.5-3,22.41-0.22c11.91,2.77,35.81,13.41,57.66,4.81c0,0-11.62,1.54-30.74-6.1
			C192.4,170.98,173.2,173.54,162.17,180.13'
				/>
				<path
					className={SCSS.Blue}
					d='M227.33,180.98l0.53-99.66c0,0-3.83,39-40.05,89.16C187.8,170.48,199.09,168.12,227.33,180.98'
				/>
				<path
					className={SCSS.Green}
					d='M228.87,181.47c0,0,12-26.72,0.35-66.79C229.22,114.68,270.93,161.62,228.87,181.47'
				/>
				<g>
					<path
						className={SCSS.Blue}
						d='M106.43,243.05h-2.7c-1.99,0-2.92,1.28-2.92,3.2v4.06c0,2.63,0.57,3.63,2.85,4.84l11.46,6.26
				c2.49,1.35,3.56,3.49,3.56,6.34v11.89c0,4.34-1.78,6.48-6.26,6.48H97.81c-4.48,0-6.19-2.14-6.19-6.48v-14.02l8.68,4.7v5.84
				c0,1.92,1,3.2,2.92,3.2h3.56c1.92,0,2.85-1.28,2.85-3.2v-4.56c0-2.63-0.57-3.63-2.78-4.84l-11.53-6.19
				c-2.49-1.35-3.49-3.49-3.49-6.34v-11.46c0-4.34,1.78-6.48,6.19-6.48h13.74c4.48,0,6.26,2.14,6.26,6.48v13.1l-8.68-4.7v-4.91
				C109.35,244.33,108.42,243.05,106.43,243.05'
					/>
					<path
						className={SCSS.Blue}
						d='M132.77,246.61h10.25c3.84,0,5.34,1.78,5.34,5.48v30.4l2.06,3.63h-12.46l2.06-3.63v-26.27
				c0-2.14-0.93-3.42-3.06-3.42h-4.2v29.69l2.06,3.63h-12.46l2.07-3.63v-41l-2.07-3.63l10.39-2.28V246.61z'
					/>
					<path
						className={SCSS.Blue}
						d='M178.11,262.77l-15.8,8.61v5.98c0,1.57,0.64,2.56,2.28,2.56h3.2c1.64,0,2.28-1,2.28-2.56v-4.13l8.04-4.34
				v11.74c0,3.7-1.49,5.48-5.34,5.48h-13.45c-3.84,0-5.34-1.78-5.34-5.48v-28.54c0-3.7,1.49-5.48,5.34-5.48h13.45
				c3.84,0,5.34,1.78,5.34,5.48V262.77z M162.31,263.69l5.69-3.2c1.35-0.78,1.78-1.35,1.78-2.99v-2.14c0-1.57-0.64-2.56-2.35-2.56
				h-2.85c-1.64,0-2.28,1-2.28,2.56V263.69z'
					/>
					<polygon
						className={SCSS.Blue}
						points='192.14,282.49 194.2,286.12 181.74,286.12 183.81,282.49 183.81,241.48 181.74,237.85 
				192.14,235.58 			'
					/>
					<path
						className={SCSS.Blue}
						d='M207.16,278.15c0,1.21,0.57,1.78,1.85,1.78l3.06-1.64v7.83h-7.9c-3.77,0-5.34-1.78-5.34-5.48V252.8h-3.13
				v-6.19h3.13v-5.2l8.33-1.99v7.19h4.91v6.19h-4.91V278.15z'
					/>
					<path
						className={SCSS.Blue}
						d='M240.33,262.77l-15.8,8.61v5.98c0,1.57,0.64,2.56,2.28,2.56h3.2c1.64,0,2.28-1,2.28-2.56v-4.13l8.04-4.34
				v11.74c0,3.7-1.49,5.48-5.34,5.48h-13.45c-3.84,0-5.34-1.78-5.34-5.48v-28.54c0-3.7,1.49-5.48,5.34-5.48h13.45
				c3.84,0,5.34,1.78,5.34,5.48V262.77z M224.53,263.69l5.69-3.2c1.35-0.78,1.78-1.35,1.78-2.99v-2.14c0-1.57-0.64-2.56-2.35-2.56
				h-2.85c-1.64,0-2.28,1-2.28,2.56V263.69z'
					/>
					<path
						className={SCSS.Blue}
						d='M254.21,282.49l2.06,3.63h-12.46l2.07-3.63v-32.25l-2.07-3.63h18.65v9.61l-5.2-3.42h-0.71
				c-1.71,0-2.35,1-2.35,2.56V282.49z'
					/>
					<path
						className={SCSS.Blue}
						d='M299.41,260.85c4.13,0.29,6.19,2.28,6.19,6.83v11.96c0,4.34-1.78,6.48-6.19,6.48h-23.21l2.21-3.99v-41.86
				l-2.21-3.99h22.71c4.41,0,6.26,2.14,6.26,6.48v11.25C305.18,258.43,303.26,260.42,299.41,260.85 M287.38,257.5h5.2
				c2.56,0,3.77-1.71,3.77-4.2v-6.05c0-2.49-1.21-4.2-3.77-4.2h-5.2V257.5z M296.64,268.39c0-2.49-1.21-4.2-3.84-4.2h-5.41v15.16
				h5.41c2.63,0,3.84-1.71,3.84-4.2V268.39z'
					/>
					<path
						className={SCSS.Blue}
						d='M310.38,272.95c0-2.63,0.92-4.56,3.27-5.84l12.53-6.83v-4.91c0-1.57-0.64-2.56-2.35-2.56h-2.56
				c-1.64,0-2.35,1-2.35,2.56v2.85l-7.97,4.34v-10.46c0-3.7,1.49-5.48,5.34-5.48h12.88c3.77,0,5.34,1.78,5.34,5.48v30.4l2.07,3.63
				h-20.86c-3.84,0-5.34-1.78-5.34-5.48V272.95z M326.18,279.92v-11.82l-4.91,2.7c-1.99,1.14-2.63,1.99-2.63,4.41v1.28
				c0,2.14,1,3.42,3.13,3.42H326.18z'
					/>
					<path
						className={SCSS.Blue}
						d='M357.43,290.39c-0.78,4.27-2.78,6.05-7.4,6.05h-10.25v-8.12l3.77,1.99h0.78c1.99,0,2.56-1.71,2.06-4.2
				l-6.83-35.88l-2.49-3.63h13.1l-1.71,3.7l3.92,29.61l3.99-29.61l-1.71-3.7h12.67l-2.49,3.63L357.43,290.39z'
					/>
				</g>
				<text
					className={SCSS.Availability}
					transform='matrix(1 0 0 1 86.2363 359.2436)'
				>
					AVAILABILITY
				</text>
			</g>
			<path
				className={SCSS.Compass}
				d='M229.47,468.99c-45.87,0-83.06,37.19-83.06,83.06c0,42.73,32.26,77.91,73.76,82.54V521.67l-48.31,48.31
		l-13.16-13.16l57.61-57.61l13.16-13.16l13.16,13.16l57.61,57.61l-13.16,13.16l-48.31-48.31v112.92
		c41.49-4.63,73.76-39.81,73.76-82.54C312.53,506.18,275.35,468.99,229.47,468.99z'
			/>
		</g>
	);
};

export default Logo;
