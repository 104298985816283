import SCSS from './SVG.module.scss';

const LabelBlack = () => {
	return (
		<g id='BLACK_LABELS' className={SCSS.LabelBlack}>
			<text
				className={SCSS.Small}
				transform='matrix(1 0 0 1 1432.5122 410.5002)'
			>
				BEACH
			</text>
			<text
				className={SCSS.Small}
				transform='matrix(1 0 0 1 1465.7573 331.9018)'
			>
				<tspan x='0' y='0'>
					PLAY
				</tspan>
				<tspan x='-10.41' y='13.2'>
					GROUND
				</tspan>
			</text>
			<text
				className={SCSS.Small}
				transform='matrix(0.5503 -0.835 0.835 0.5503 1184.3253 532.6548)'
			>
				<tspan x='0' y='0'>
					AMENITY
				</tspan>
				<tspan x='-1.59' y='13.2'>
					BUILDING
				</tspan>
			</text>
			<text
				className={SCSS.Small}
				transform='matrix(1 0 0 1 1501.0641 423.5014)'
			>
				BEACH
			</text>
			<text
				className={SCSS.Medium}
				transform='matrix(0.9565 -0.2918 0.2918 0.9565 1133.9165 147.7714)'
			>
				CAMPBELL ROAD
			</text>
			<text
				className={SCSS.Medium}
				transform='matrix(0.5422 -0.8402 0.8402 0.5422 483.7629 772.6487)'
			>
				CAMPBELL ROAD
			</text>
			<text
				className={SCSS.Medium}
				transform='matrix(0.2489 0.9685 -0.9685 0.2489 950.2328 387.3473)'
			>
				CAMPBELL PLACE
			</text>
			<text
				className={SCSS.Medium}
				transform='matrix(0.4134 -0.9105 0.9105 0.4134 1107.2104 888.3477)'
			>
				CAMPBELL PLACE
			</text>
			<g>
				<text
					className={SCSS.Bridge}
					transform='matrix(0.9291 -0.3698 0.3698 0.9291 1367.2847 59.6591)'
				>
					<tspan x='0' y='0'>
						BRIDGE TO
					</tspan>
					<tspan x='2.93' y='12'>
						KELOWNA
					</tspan>
					<tspan x='33.9' y='24'>
						600M
					</tspan>
				</text>
			</g>
			<polygon points='1451.6,34.54 1436.51,25.73 1446.49,51.25 	' />
		</g>
	);
};

export default LabelBlack;
